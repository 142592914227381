import React from "react";
import YoungWoman from "../assets/images/download 5.png";
import OldWoman from "../assets/images/responselady67 1.png";
import AgeSlider from "../assets/images/slider_example.svg";
import "../styles/index.scss";
import Footer from "../components/footer";
// import { NavLink } from "react-router-dom";
import Nav from "../components/nav";
import Button from "../components/button";
import ErrorBoundary from "../components/ErrorBoundary";
import MESH from "../assets/images/ai__mesh.svg";
import { NavLink, Redirect, useHistory } from "react-router-dom";

export default function Landing() {
  const history = useHistory();
  const [acceptTerms, setAcceptTerms] = React.useState({ acceptTerms: null });
  // const [showAcceptErr, setShowAcceptErr] = React.useState(false);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.checked;

    setAcceptTerms({ ...acceptTerms, [name]: value });
    localStorage.setItem("accept", value);
  };

  const handleClick = () => {
    // window.location.href = "/upload";
    history.push("/upload");
  };

  React.useEffect(() => {
    const acceptTerms = JSON.parse(localStorage.getItem("accept"));
    setAcceptTerms({ ...acceptTerms, acceptTerms });
  }, []);

  return (
    <ErrorBoundary>
      <div className="main">
        <Nav />
        <object className="svg" type="image/svg+xml" data={MESH}>
          Your browser does not support SVGs
        </object>

        <section className="hero animate__animated animate__fadeIn ">
          {/* <h1 className="">Marketing/Catching Caption</h1> */}
          <p>
            How handsome/beautiful are you when you are young or old? Take a
            look at your old age and facial expression.
          </p>
          <p>
            Are you in your 20s? Care about your look/how your look will be in
            10yrs, 20yrs, even in your 80s? Let have a look into how you age. It
            is as simple as 1-2-3:
          </p>
        </section>
        <section id="process" className="process">
          <header>
            <h1>How it works</h1>
            <p>Let check out our Older look in 1-2-3 steps, shall we?</p>
          </header>

          <ul className="list__item--container">
            <li className="list--item one animate__animated animate__fadeInUp">
              <img src={YoungWoman} alt="woman-without-wrinkles" />
              <h4>Select Image</h4>
              <p>
                Grant the browser permission to access your system or mobile
                phone camera; doing so will enable the device to take your
                picture in realtime
              </p>
            </li>
            <li className="list--item two">
              <img src={AgeSlider} alt="age-slider" />
              <h4>Choose Age</h4>
              <p>
                Adjust the slider to choose how old is the look you wanted to
                see and also you can check your look when you are young.
              </p>
            </li>
            <li className="list--item three">
              <img src={OldWoman} alt="woman-with-wrinkles" />

              <h4>See Results</h4>
              <p>Wait and get the newly age-generated look of yourself</p>
            </li>
          </ul>
        </section>
        <section className="begin animate__fadeInUp" id="begin">
          <h1>Let's Do this!</h1>

          <PrivacyPolicySummary
            acceptTerms={acceptTerms}
            handleClick={handleClick}
            handleChange={handleChange}
          />
        </section>
        <Footer />
      </div>
    </ErrorBoundary>
  );
}

const PrivacyPolicySummary = ({
  acceptTerms,
  handleClick,
  handleChange,
  ...props
}) => {
  return (
    <div className="privacy__policy--summary">
      <h4>Quick Note</h4>
      <p>
        At iQubeSolutions, accessible from{" "}
        <a href="https://iqubesolutions.com.ng">iqubesolutions.com.ng</a>, one
        of our main priorities is the privacy of our visitors. This Terms AND
        Conditions document contains types of information that is collected and
        recorded by iQubeSolutions and how we use it.{" "}
        <NavLink
          to="/terms"
          style={{ color: "#db2763", textDecoration: "none", fontSize: "18px" }}
        >
          Read More
        </NavLink>
      </p>

      <label htmlFor="" className="terms">
        <input
          type="checkbox"
          name="acceptTerms"
          onChange={handleChange}
          checked={acceptTerms.acceptTerms}
          id="termsCheckbox"
        />
        I agree to the
        <span
          className="terms__conditions"
          onClick={() => history.push("/terms")}
        >
          <NavLink
            to="/terms"
            style={{
              color: "#db2763",
              textDecoration: "none",
              // fontSize: "18px",
            }}
          >
            {" "}
            Terms and Conditions
          </NavLink>
        </span>
      </label>
      <Button
        type="button"
        className={acceptTerms.acceptTerms ? "button" : "button--disabled"}
        disabled={!acceptTerms.acceptTerms}
        onClick={handleClick}
      >
        Begin
      </Button>
    </div>
  );
};
