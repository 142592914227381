import React from "react";
import "../styles/button.scss";


export default function Button({ children, className, disabled, ...props }) {
  return (
    // <div className="button__container">
      <button
        type="button"
        className={className}
        {...props}
        disabled={disabled}
      >
        {children}
      </button>
    // </div>
  );
}