import React from "react";
import { NavLink } from "react-router-dom";
import Nav from "../components/nav";
import "../styles/privacypolicy.scss";

export default function privacypolicy() {
  return (
    <>
      <Nav />
      <div className="privacypolicy">
        <p>
          <span>
            Welcome to AgingApp, an online platform leveraging on computer
            vision to predict how your face will respond to ageing in the near
            and distant future. Our Privacy Policy describes our policies and
            procedures on the collection, use and disclosure of your information
            when you use the service and tells you about your privacy rights and
            how the law protects you. This Privacy Policy applies to the general
            public (individually referred to as &ldquo;you&rdquo; ) of our
            website to which this Privacy Policy is posted.&nbsp;
          </span>
        </p>
        <p>
          <span>
            We use Your Personal data to provide and improve the Service. By
            using the Service, You agree to the collection and use of
            information in accordance with this Privacy Policy.
          </span>
        </p>
        <h3>
          <strong>APPLICATION OF THIS PRIVACY POLICY</strong>
        </h3>
        <p>
          <span>
            This Privacy Policy applies to your use of (regardless of means of
            access) our Platforms. You may access or use our Platforms through a
            desktop, laptop, mobile phone, tablet, or other consumer electronic
            devices (each, a "device" ).
          </span>
        </p>
        <h3>
          <strong>INFORMATION WE COLLECT</strong>
        </h3>
        <p>
          <span>
            To use the core functionality of our website, you will be required
            to upload an image of yourself or whosoever you want to age.&nbsp;
          </span>
        </p>
        <h3>
          <strong>USE OF THE INFORMATION WE COLLECT</strong>
        </h3>
        <p>
          <span>
            To perform the core functionality: The picture upload is needed to
            use the ageing functionality as the necessary process will be
            carried out on the uploaded picture and the result will be displayed
            in place of the uploaded picture.
          </span>
        </p>
        <p>
          <span>
            To maintain and improve our service: The pictures uploaded will be
            further used to train the algorithm to further increase its
            accuracy.
          </span>
        </p>
        <h3>
          <strong>RETENTION OF YOUR PERSONAL DATA</strong>
        </h3>
        <p>
          <span>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </span>
        </p>
        <p>
          <span>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </span>
        </p>
        <h3>
          <strong>TRANSFER OF YOUR PERSONAL DATA</strong>
        </h3>
        <p>
          <span>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to &mdash; and maintained on &mdash;
            computers located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </span>
        </p>
        <p>
          <span>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </span>
        </p>
        <p>
          <span>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </span>
        </p>
        <h3>
          <strong>DISCLOSURE OF YOUR PERSONAL DATA</strong>
        </h3>
        <h3>
          <strong>Business Transactions</strong>
        </h3>
        <p>
          <span>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </span>
        </p>
        <h3>
          <strong>Law enforcement</strong>
        </h3>
        <p>
          <span>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </span>
        </p>
        <h3>
          <strong>Other legal requirements</strong>
        </h3>
        <p>
          <span>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </span>
        </p>
        <ul>
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
          <li>Security of Your Personal Data</li>
        </ul>
        <p>
          <span>
            The security of your personal data is important to us, but remember
            that no method of transmission over the internet or method of
            electronic storage is 100% secure. While we strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </span>
        </p>
        <h3>
          <strong>LINK TO OTHER WEBSITES</strong>
        </h3>
        <p>
          <span>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </span>
        </p>
        <p>
          <span>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </span>
        </p>
        <h3>
          <strong>CHANGES TO THIS PRIVACY POLICY</strong>
        </h3>
        <p>
          <span>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </span>
        </p>
        <p>
          <span>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the "last
            updated" date at the top of this Privacy Policy.
          </span>
        </p>
        <p>
          <span>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </span>
        </p>
        <p>
          <br />
          <br />
        </p>
        <h3>
          <strong>CONTACT US</strong>
        </h3>
        <p>
          <span>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </span>
        </p>
        <p>
          <span>By visiting this page on our website: </span>
          <NavLink to="/contact">
            <span>https://agingapp.iqubesolutions.com.ng/contact</span>
          </NavLink>
        </p>
      </div>
    </>
  );
}
