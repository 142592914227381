import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "./styles/base.scss";
// import "./styles/index.scss"
import 'normalize.css'; // Note this




ReactDOM.render(
    <App/>,
    document.getElementById('root')
  );