import React, { Suspense } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Contact from './routes/Contact';
import Landing from './routes/Landing'
import privacypolicy from './routes/privacypolicy';
import Terms from './routes/Terms';
const Upload = React.lazy(() => import('./routes/Upload'));

export default function app() {
    return (
            <Router>
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route exact path="/" component={Landing} />
                        <Route exact path="/upload" component={Upload} />
                        <Route exact path="/policy" component={privacypolicy} />
                        <Route exact path="/contact" component={Contact} />
                        <Route exact path="/terms" component={Terms} />
                    </Switch>
                </Suspense>
            </Router>
    )
}
