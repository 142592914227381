import React from "react";
import Button from "./button";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.log(error, errorInfo);
  }

  handlePageReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error__boundary--container">
          <header>
            <h2>Something went wrong</h2>
          </header>
          <p>Do bear with us, we are currently working on it.</p>

          <Button className="button">
            <span onClick={() => this.handlePageReload()}>Reload Page</span>
          </Button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
