import React from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import '../styles/nav.scss'

export default function Nav() {
  const history = useHistory();
  const urlLocation =
    history.location.pathname === "/upload" ||
    history.location.pathname === "/policy" ||
    history.location.pathname === "/terms" ||
    history.location.pathname === "/contact";

  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    // console.log("clicked");
    setOpen(!open);
  };

  return (
    <nav className={`${open ? "show__nav" : "hide__nav"}`}>
      <Link to="/">
        <p>
          <strong style={{ color: "orange" }}>AGEING APP</strong>
          <br />
          <span style={{ fontSize: "14px" }}> by iQubeLabs</span>
        </p>
      </Link>

      {open ? (
        <i onClick={() => handleClick()} class="fas fa-times"></i>
      ) : (
        <i
          onClick={() => handleClick()}
          className={` fas fa-bars
          fa--ham`}
        ></i>
      )}

      <ul>
        {!urlLocation && (
          <>
            <a href="#begin">
              <li>Play</li>
            </a>

            <a href="#process">
              <li>How it works</li>
            </a>
            <li>
            <NavLink to="/contact" activeClassName="active">Contact Us</NavLink>
            </li>
            
          </>
        )}
        <li>
          <NavLink to="/policy" activeClassName="active">
            Policy
          </NavLink>
        </li>
        <li>
          <a  href="https://docs.google.com/forms/d/15qkaIcsF-kVt7Jsy64d-DI3BDgDMgDHCTpyOdC53p48/viewform?chromeless=1&edit_requested=true" activeClassName="active">
            Drop a Feedback
          </a>
        </li>
      </ul>
    </nav>
  );
}
