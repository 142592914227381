import React from "react";
import { NavLink } from "react-router-dom";

export default function Footer() {
  return (
    <>
      {/* <hr /> */}

      <footer>
        Copyright &copy; <span id="copyright">{new Date().getFullYear()}</span>,
        &nbsp;
        <span>
          <a href="https://www.iqubesolutions.com.ng">iQubeLabs</a>
        </span>
        <hr/>
        <div className="links">
          <NavLink to="/policy">Privacy Policy</NavLink> ||{" "}
          <NavLink to="/terms">Terms and Conditions</NavLink>
        </div>
      </footer>
    </>
  );
}
