import React from "react";
import Button from "../components/button";
import Footer from "../components/footer";
import Nav from "../components/nav";
import "../styles/contact.scss";
import axios from "axios";
import * as yup from "yup"; // for everything
// import { string, object } from "yup"; // for only what you need

export default function Contact() {
  const GOOGLE_FORM_MESSAGE_ID = "entry.326955045";
  const GOOGLE_FORM_EMAIL_ID = "entry.879531967";
  const GOOGLE_FORM_FEEDBACK_ID = "entry.1591633300";
  const GOOGLE_FORM_NAME_ID = "entry.485428648";

  const GOOGLE_FORM_ACTION_URL =
    "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfmQ_UFXb_81iA-QCXpqb5BmE5iaHOXgapqqzHZJc-q2um6Zg/formResponse";
  const CORS_PROXY = "https://cors-anywhere.herokuapp.com/";

  const [state, setState] = React.useState({
    [GOOGLE_FORM_NAME_ID]: "",
    [GOOGLE_FORM_EMAIL_ID]: "",
    [GOOGLE_FORM_FEEDBACK_ID]: "",
    [GOOGLE_FORM_MESSAGE_ID]: "",
  });

  const [errorState, setError] = React.useState({
    error: "",
    success: "",
    [GOOGLE_FORM_EMAIL_ID]: "",
  });
  
  const [submitted, setSubmitted] = React.useState({
    error: false,
    success: false,
    type: "",
    [GOOGLE_FORM_EMAIL_ID]: "",
  });

  let schema = yup.object().shape({
    [GOOGLE_FORM_EMAIL_ID]: yup.string().required().email(),
    [GOOGLE_FORM_FEEDBACK_ID]: yup.string().required(),
    // [GOOGLE_FORM_NAME_ID]: yup.string().required(),
  });

  const handleSubmit = () => {
    const regex = /\S+@\S+\.\S+/.test(state[GOOGLE_FORM_EMAIL_ID]);
    let validError;

    const formData = new FormData();
    formData.append(GOOGLE_FORM_MESSAGE_ID, state[GOOGLE_FORM_MESSAGE_ID]);
    formData.append(GOOGLE_FORM_EMAIL_ID, state[GOOGLE_FORM_EMAIL_ID]);
    formData.append(GOOGLE_FORM_NAME_ID, state[GOOGLE_FORM_NAME_ID]);
    formData.append(GOOGLE_FORM_FEEDBACK_ID, state[GOOGLE_FORM_FEEDBACK_ID]);

    // console.log(regex);

    // console.log(state[GOOGLE_FORM_EMAIL_ID]);
    const config = {
      "Content-Type": "application/json",
    };

    schema.isValid(state).then(function (valid) {
      validError = valid; // => true
      setError({ ...errorState, error: !errorState.error });
    });

    if (regex && !validError) {
      axios
        .post(CORS_PROXY + GOOGLE_FORM_ACTION_URL, formData, config)
        .then((data) => {
          setState({
            ...state,
            [GOOGLE_FORM_NAME_ID]: "",
            [GOOGLE_FORM_EMAIL_ID]: "",
            [GOOGLE_FORM_FEEDBACK_ID]: "",
            [GOOGLE_FORM_MESSAGE_ID]: "",
          });

          setSubmitted({
            ...submitted,
            error: false,
            success: true,
            type: "success",
            // [GOOGLE_FORM_EMAIL_ID]: "",
          });
          // console.log(data);
        })
        .catch((err) => {
          setSubmitted({
            ...submitted,
            error: true,
            success: false,
            type: "fail",
            // [GOOGLE_FORM_EMAIL_ID]: "",
          });
          // console.log("err o", err);
        });
    } else {
      setSubmitted({
        ...submitted,
        error: true,
        success: false,
        type: "fail",
        // [GOOGLE_FORM_EMAIL_ID]: "",
      });
    }

    // schema.isValid(state).then(function (valid) {
    //   valid; // => true
    //   setError({ ...errorState, error: !errorState.error });
    // });

    // schema.validate(state).catch(function (err) {
    //   err.name; // => 'ValidationError'
    //   err.errors; // => ['Deve ser maior que 18']

    //   setError({
    //     ...errorState,
    //     error: true,
    //     [GOOGLE_FORM_EMAIL_ID]: err.message,
    //     // [GOOGLE_FORM_FEEDBACK_ID]: err.message,
    //     errors: err.errors,
    //   });
    // });
  };

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleBanner = () => {
    setSubmitted({
      ...submitted,
      error: false,
      success: false,
      // [GOOGLE_FORM_EMAIL_ID]: "",
    });
  };

  return (
    <>
      <Nav />
      <div className="container">
        <header>
          <h1>Let's get in touch</h1>
        </header>
        <form
          action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSfmQ_UFXb_81iA-QCXpqb5BmE5iaHOXgapqqzHZJc-q2um6Zg/formResponse"
          className="form"
          method="POST"
        >
          {/* <div style={{width:"100%", border:"1px solid black"}}>{<p></p>}</div> */}
          <div className="form--line">
            <div>
              <label htmlFor="">Name</label>
              <br />
              <input
                type="text"
                onChange={handleChange}
                name={GOOGLE_FORM_NAME_ID}
                value={state[GOOGLE_FORM_NAME_ID]}
                id=""
              />
            </div>
            <div>
              <label htmlFor="">
                Email <span className="required">*</span>{" "}
              </label>
              <br />

              <input
                type="email"
                onChange={handleChange}
                name={GOOGLE_FORM_EMAIL_ID}
                id=""
                value={state[GOOGLE_FORM_EMAIL_ID]}
                required
              />
            </div>
          </div>

          <div>
            <label htmlFor="">
              Concern type <span className="required">*</span>
            </label>

            <select
              name={GOOGLE_FORM_FEEDBACK_ID}
              id=""
              onChange={handleChange}
              value={state[GOOGLE_FORM_FEEDBACK_ID]}
            >
              <option value="">Please Select an Option</option>
              <option value="Terms & Conditions">Terms and Conditions</option>
              <option value="Privacy Policy">Privacy Policy Issues</option>
              <option value="Comments">Comments</option>
              <option value="Questions and Inquiries">
                Questions and Inquiry
              </option>
            </select>
          </div>
          <div>
            <label htmlFor="">Concern</label>
            <textarea
              onChange={handleChange}
              name={GOOGLE_FORM_MESSAGE_ID}
              id=""
              cols="30"
              rows="10"
              value={state[GOOGLE_FORM_MESSAGE_ID]}
            ></textarea>
          </div>
          <Button
            className="button"
            // type="submit"
            onClick={() => handleSubmit()}
            // disabled={!errorState.error || !state[GOOGLE_FORM_EMAIL_ID]}
          >
            Send Message
          </Button>
        </form>
      </div>
      <Footer />
      <ShowError submitted={submitted} click={handleBanner} />
    </>
  );
}

const ShowError = ({ submitted, click }) => {
  const { success, error, type } = submitted;
  switch (type) {
    case "success":
      return (
        <div
          onClick={() => click()}
          className={success ? "success__container" : "hidden"}
        >
          <p>Response Successfully Submitted</p>
        </div>
      );
    case "fail":
      return (
        <div
          onClick={() => click()}
          className={error ? "error__container" : "hidden"}
          // disabled={}
        >
          <p>An error occurred, Please check your information</p>
        </div>
      );
    default:
      return "";
  }
};
